<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <!-- <v-flex
                  :class="'lg5-custom'"
                  xs12
                  md3>
                  <v-daterange
                    v-model="range"
                    :presets="presets"
                    :input-props="inputProps"
                    :locale="$i18n.locale"
                    :start-label="$t('evoucher.issuance.startDate')"
                    :end-label="$t('evoucher.issuance.endDate')"
                    :menu-props="menuProps"
                    :separator-label="$t('evoucher.to')"
                    style="width: 100%;"
                    display-format="dd/MM/yyyy"
                  />
                </v-flex> -->
                <v-flex
                  xs12
                  md2>
                  <v-menu
                    ref="menu"
                    :clearable="true"
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="startDateFormatted"
                        :label="$t('evoucher.issuance.fromDate')"
                        persistent-hint
                        @blur="date = parseDate(startDateFormatted)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      :clearable="true"
                      v-model="startDate"
                      :min="minStartDate"
                      no-title
                      @input="menuStartDate = false"
                    />
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md2>
                  <v-menu
                    ref="menu"
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :clearable="true"
                        v-model="endDateFormatted"
                        :label="$t('evoucher.issuance.toDate')"
                        persistent-hint
                        @blur="date = parseDate(endDateFormatted)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      :min="minEndDate"
                      no-title
                      @input="menuEndDate = false"
                    />
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    :clearable="true"
                    v-model="search.voucherName"
                    :label="
                      `${$t(
                        'report.quantityVoucherSupplierToAgent.workpieceCode'
                      )}/${$t('voucherUsed.voucherName')}`
                    "
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    :clearable="true"
                    v-model="search.issuer"
                    :label="$t('report.supplier.issuer')"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="getVoucherList()"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <!-- Receive list -->
        <material-card
          :title="$t('workpiece.issuedVoucherList')"
          color="green"
          flat
          full-width
        >
          <template>
            <!-- Create check voucher -->
            <v-btn
              v-if="!isEntityDisabled()"
              color="success"
              dark
              style="float: right;"
              @click="onExportExcel()"
            >
              <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
              <span style="text-transform: none;">{{
                $t("voucherReport.exportExcel")
              }}</span>
            </v-btn>
          </template>
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="voucherReportHeaders"
            :items="voucherReportList"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }">
              <td
                v-for="(header, index) in voucherReportHeaders"
                :key="header.value + index"
                class="text-xs-center"
              >
                <div
                  v-if="header.value == 'price'"
                  style="max-height: 150px; overflow: auto; white-space: nowrap;"
                >
                  <p>
                    {{ formatMoney(item[header.value]) }}
                  </p>
                </div>
                <span v-else>{{ item[header.value] }}</span>
              </td>
            </template>
          </v-data-table>
        </material-card>
        <pagination
          :total="paginate.totalPage"
          :current-page="paginate.currentPage"
          :row-per-page="paginate.rowPerPage"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dateUtils from 'utils/dateUtils'
import functionUtils from 'utils/functionUtils'
import moment from 'moment'
import XLSX from 'xlsx'
import { ISO_FORMAT } from 'utils/constants'
import { format, subDays } from 'date-fns'
export default {
  data: () => ({
    voucherReportHeaders: [
      { text: 'common.nonumber', value: 'stt', align: 'center' },
      { text: 'evoucher.issuedCode', value: 'issuedCode', align: 'center' },
      { text: 'evoucher.dateIssued', value: 'dateIssued', align: 'center' },
      { text: 'report.supplier.issuer', value: 'issuer', align: 'center' },
      {
        text: 'report.quantityVoucherSupplierToAgent.issueNumber',
        value: 'issueNumber',
        align: 'center'
      },
      {
        text: 'report.quantityVoucherSupplierToAgent.workpieceCode',
        value: 'code',
        align: 'center'
      },
      {
        text: 'voucherUsed.voucherName',
        value: 'voucherName',
        align: 'center'
      },
      { text: 'voucherUsed.price', value: 'price', align: 'center' },
      {
        text: 'workpiece.quantityDistributedAgent',
        value: 'quantityDistributedAgent',
        align: 'center'
      },
      {
        text: 'report.supplier.numberOfCustomersReceived',
        value: 'numberOfCustomersReceived',
        align: 'center'
      },
      {
        text: 'report.supplier.numberOfCustomersUsed',
        value: 'numberOfCustomersUsed',
        align: 'center'
      }
    ],
    voucherReportList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    isLoading: false,
    search: {
      voucherName: null,
      issuer: null
    },
    startDate: null,
    startDateFormatted: null,
    menuStartDate: false,
    minStartDate: null,
    endDate: null,
    endDateFormatted: null,
    menuEndDate: false,
    minEndDate: null,
    exportExcelData: {
      headerList: [
        'common.nonumber',
        'evoucher.issuedCode',
        'evoucher.dateIssued',
        'report.supplier.issuer',
        'report.quantityVoucherSupplierToAgent.issueNumber',
        'report.quantityVoucherSupplierToAgent.workpieceCode',
        'voucherUsed.voucherName',
        'voucherUsed.price',
        'workpiece.quantityDistributedAgent',
        'report.supplier.numberOfCustomersReceived',
        'report.supplier.numberOfCustomersUsed'
      ],
      dataList: []
    },
    //
    inputProps: { solo: false, style: { width: '270px' } },
    menuProps: { offsetY: true, closeOnContentClick: false },
    presets: [
      {
        label: 'rangeCalendar.last30Days',
        range: [
          format(subDays(new Date(), 30), ISO_FORMAT),
          format(subDays(new Date(), 1), ISO_FORMAT)
        ]
      },
      {
        label: 'rangeCalendar.lastMonth',
        range: [
          format(
            new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
            ISO_FORMAT
          ),
          format(
            new Date(new Date().getFullYear(), new Date().getMonth(), 0),
            ISO_FORMAT
          )
        ]
      },
      {
        label: 'rangeCalendar.currentMonth',
        range: [
          format(
            new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            ISO_FORMAT
          ),
          format(
            new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
            ISO_FORMAT
          )
        ]
      }
    ]
  }),
  computed: {
    ...mapGetters([
      'GET_QUANTITY_VOUCHER_CREATED_IN_SUPPLIER_LIST_DATA'
    ])
  },
  watch: {
    startDate (val) {
      this.startDateFormatted = this.formatDate(this.startDate)
      if (this.startDate >= this.endDate) {
        this.endDate = moment(this.startDate, 'YYYY-MM-DD')
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
      this.minEndDate = moment(this.startDate, 'YYYY-MM-DD')
        .add(1, 'days')
        .format('YYYY-MM-DD')
    },
    endDate (val) {
      this.endDateFormatted = this.formatDate(this.endDate)
    },
    GET_QUANTITY_VOUCHER_CREATED_IN_SUPPLIER_LIST_DATA () {
      let res = this.GET_QUANTITY_VOUCHER_CREATED_IN_SUPPLIER_LIST_DATA
      // Handle paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let data = res.results
      this.voucherReportList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          issuedCode: functionUtils.concatSuffixPrefix(data[i].issuance),
          dateIssued: !functionUtils.isEmptyString(data[i].issuance.date_created)
            ? moment(data[i].issuance.date_created).format('HH:mm DD/MM/YYYY') : null,
          issueNumber: data[i].statistics_quantity.total_voucher,
          code: data[i].code,
          voucherName: data[i].name,
          price: data[i].value,
          quantityDistributedAgent: data[i].statistics_quantity.quantity_distributed_to_agent,
          numberOfCustomersReceived: data[i].statistics_quantity.quantity_distributed_to_customer,
          numberOfCustomersUsed: data[i].voucher_usages_count,
          issuer: data[i].user_created_info.full_name
        }
        this.voucherReportList.push(obj)
      }
    }
  },
  created () {
    this.getVoucherList()
  },
  methods: {
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price)
    },
    /**
     * Format date
     */
    formatDate: function (date) {
      return dateUtils.formatBeautyDate(date)
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.getVoucherList()
    },
    getVoucherList: function () {
      let filter = {
        params: {
          page: this.paginate.currentPage,
          includeDisabled: true,
          voucherName: this.search.voucherName,
          issuer: this.search.issuer,
          startDate: !functionUtils.isEmptyString(this.startDateFormatted)
            ? dateUtils.formatDate(this.startDateFormatted, dateUtils.STATIC_FORMAT_DATE, dateUtils.STATIC_FORMAT_DATE_SQL) : null,
          endDate: !functionUtils.isEmptyString(this.endDateFormatted)
            ? dateUtils.formatDate(this.endDateFormatted, dateUtils.STATIC_FORMAT_DATE, dateUtils.STATIC_FORMAT_DATE_SQL) : null
        }
      }
      this.isLoading = true
      this.GET_QUANTITY_VOUCHER_CREATED_IN_SUPPLIER_LIST(filter)
        .then(
          function () {
            this.isLoading = false
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    onExportExcel: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      let filter = {
        params: {
          includeDisabled: true,
          voucherName: this.search.voucherName,
          issuer: this.search.issuer,
          startDate: !functionUtils.isEmptyString(this.startDateFormatted)
            ? dateUtils.formatDate(this.startDateFormatted, dateUtils.STATIC_FORMAT_DATE, dateUtils.STATIC_FORMAT_DATE_SQL) : null,
          endDate: !functionUtils.isEmptyString(this.endDateFormatted)
            ? dateUtils.formatDate(this.endDateFormatted, dateUtils.STATIC_FORMAT_DATE, dateUtils.STATIC_FORMAT_DATE_SQL) : null
        }
      }
      this.isLoading = true
      this.GET_QUANTITY_VOUCHER_CREATED_IN_SUPPLIER_LIST(filter)
        .then(
          function (res) {
            this.isLoading = false
            let data = res.data
            let dataList = this.getDataExcelJson(
              this.exportExcelData.headerList,
              data
            )
            this.createExcelFile(dataList)
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    getDataExcelJson: function (headerList, dataList) {
      let resultList = []

      //
      // let headerListLength = headerList.length
      let nonumberHeader = headerList[0]
      let issuedCodeHeader = headerList[1]
      let dateIssuedHeader = headerList[2]
      let issuerHeader = headerList[3]
      let issueNumberHeader = headerList[4]
      let workpieceCodeHeader = headerList[5]
      let voucherNameHeader = headerList[6]
      let priceHeader = headerList[7]
      let quantityDistributedAgentHeader = headerList[8]
      let numberOfCustomersUsedHeader = headerList[10]
      let numberOfCustomersReceivedHeader = headerList[9]

      dataList.forEach((data, index) => {
        let item = {
          [this.$t(nonumberHeader)]: index + 1,
          [this.$t(issuedCodeHeader)]: functionUtils.concatSuffixPrefix(data.issuance),
          [this.$t(dateIssuedHeader)]: !functionUtils.isEmptyString(data.issuance.date_created)
            ? moment(data.issuance.date_created).format('HH:mm DD/MM/YYYY') : null,
          [this.$t(issuerHeader)]: data.user_created_info.full_name,
          [this.$t(issueNumberHeader)]: data.statistics_quantity.total_voucher,
          [this.$t(workpieceCodeHeader)]: data.code,
          [this.$t(voucherNameHeader)]: data.name,
          [this.$t(priceHeader)]: data.value,
          [this.$t(quantityDistributedAgentHeader)]: data.statistics_quantity.quantity_distributed_to_agent,
          [this.$t(numberOfCustomersReceivedHeader)]: data.statistics_quantity.quantity_distributed_to_customer,
          [this.$t(numberOfCustomersUsedHeader)]: data.voucher_usages_count
          //
        }
        resultList.push(item)
      })
      return resultList
    },
    createExcelFile: function (jsonData) {
      let dataList = []

      dataList = jsonData
      // export json to Worksheet of Excel
      // only array possible
      var assetFile = XLSX.utils.json_to_sheet(dataList)
      // var pokemonWS = XLSX.utils.json_to_sheet(this.Datas.pokemons)

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, assetFile, 'ReportVoucherCreatedSheet') // sheetAName is name of Worksheet
      // XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons')

      // export Excel file
      XLSX.writeFile(wb, 'ReportVoucherCreated.xlsx') // name of the file is 'book.xlsx'
    },
    ...mapActions([
      'GET_QUANTITY_VOUCHER_CREATED_IN_SUPPLIER_LIST'
    ])
  }
}
</script>

<style></style>
